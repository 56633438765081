<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Оценки</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество {{ ratesList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ratesList"
          :loading="loading"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <span>
              {{ dateFormatter(item.created_at) }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </v-col>
</template>

<script>
import ReviewsService from '@/app/Services/ReviewsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import dateFormatter from '@/app/Helpers/dateFormatter'

export default {
  name: 'RatesDashboard',

  mixins: [TableDialogMixin],

  data() {
    return {
      ratesList: [],
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Магазин ID',
          value: 'cart_id',
        },
        {
          sortable: false,
          text: 'Пользователь',
          value: 'user.name',
        },
        {
          sortable: false,
          text: 'Текст',
          value: 'text',
        },
        {
          sortable: true,
          text: 'Оценка',
          value: 'rating',
        },
        {
          sortable: true,
          text: 'Дата',
          value: 'created_at',
        },
      ],
    }
  },

  async created() {
    this.loading = true
    await this.getReviews()
    this.loading = false
  },

  methods: {
    dateFormatter,
    async getReviews() {
      const response = await ReviewsService.getReviews()

      if (response.status) {
        this.ratesList = response.data

        this.$store.dispatch('reviews/setReviewsData', response.data)
      }
    },
  },
}
</script>
